%font-light {
    font-weight: 300;
}

%font-normal {
    font-weight: 400;
}

%font-bold {
  font-weight: 700;
}

%font-xbold {
  font-weight: 900;
}


.uppercase {
	text-transform: uppercase;
}

::-webkit-input-placeholder { /* WebKit browsers */
    text-transform: none;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    text-transform: none;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
    text-transform: none;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
    text-transform: none;
}


.label-as-badge {
    border-radius: 1em;
}
