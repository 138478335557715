

%form-default,
.form-default {
    @extend .form-horizontal;

	.check-all {
		@include make-sm-column(24);
		padding: 0; list-style-type: none; text-align: right; margin: 0;

		li { display:inline; font-size: 12px; line-height: 12px;
			 button { background: none; border: none; outline: none;
				&:hover, &:active, &:active { text-decoration: underline; }
			 }
		}
	} // .check-all

    .form-group {

        .control-label {
            @extend .col-sm-7;

            color: $gray;

            em {
                color: $red;
            }
        }

        .controls {
            @extend .col-sm-17;

            .checkbox {
                padding-top: 0;
            }

            .alert {
                padding-top: 5px;
                padding-bottom: 5px;
                padding-left: 10px;
                margin-bottom: 5px;
            }
        }

        .controls {
            .input-group + .input-group,
            input[type=text] + input[type=text] {
                margin-top: 10px;
            }
        }

        &.group-is_danger,
        &.group-is_blocked {
            > .checkbox {
                @extend .col-sm-offset-7;

                label {
                    font-weight: 700;
                }
            }
        }

        &.group-remember,
        &.group-action {
            .controls {
                @extend .col-sm-offset-7;
            }
        }

		&.group-action-saves {
            .controls {
				@extend .col-sm-19;
                @extend .col-sm-offset-5;
            }
        }

        &.group-action,
		&.group-action-saves {
            padding-top: 30px;
            padding-bottom: 20px;
            background: $table-bg-accent;
            border-top: 1px solid $hr-border;


            clear: both;
            margin-top: 40px;

            .btn {
                @extend .btn-lg;
            }

            .modal & {
                text-align: center;

                .controls {
                    @extend .col-sm-offset-8;
                }

                .btn-delete,
                .btn-cancel { display: none; }
            }
        }

		&.group-checkbox {
			.checkbox {
				left: 15px;
				background: lighten($color-primary, 5%);
				padding-top: 10px;
				padding-bottom: 10px;
				border-radius: 4px;

				label {
					@extend %font-bold;
					color: $gray;
				}
			}
		} // &.group-checkbox
    }


	.package-options {
		.form-group {
			> .control-label {
				@extend .col-sm-12;
			}

			> .controls {
				@extend .col-sm-12;
			}
		}

	}


    .form-fieldset {
        //@extend .col-md-offset-2;
    }


    .fieldset-header {
        margin-bottom: 30px;

        .group-reference,
        .group-nom,
        .group-raison_sociale,
        .group-code,
        .group-month,
        .group-color {
            @extend .form-group-lg;
        }

        .control-label {
            display: none;
        }

        .controls {
            @include make-sm-column(24);
        }


    }

	&.form-cardgame, &.form-packages {
		ul { padding: 0; list-style-type: none; }

		.products-shelf {
			@include make-sm-column(24);

			> p { padding: 5px 15px; margin: 5px 0 0 0; font-weight: bold; border-radius: 4px; }

			.products-category { margin: 5px 0 15px 0; overflow: auto;
				> p { padding: 3px 15px; margin: 0 0 5px 0; border-bottom: 1px solid #e3e8ed; background: #f2f5f7; border-radius: 4px; }

				> ul > .products-item {
					@include make-sm-column(12);
				}
			}

			.product-checked { font-weight: bold; color: green;	}


			> ul > .products-item {
				@include make-sm-column(6);
			}
		}
	}


	.col-full {
        @extend .col-sm-24;

		> .form-group {
			> .control-label {
				@extend .col-sm-4;
			}

			> .controls {
				@extend .col-sm-20;
			}
		}

        .modal & { width: 100% }
    }


	.col-no-padding {
		> .col-left { padding-left: 0; }
		> .col-right { padding-right: 0; }
	}

    .col-left {
        @extend .col-sm-12;

        .modal & { width: 100% }
    }

    .col-right {
        @extend .col-sm-12;

        .modal & { width: 100% }
    }

    > .col-full { padding-left: 0; padding-right: 0; }
    > .col-left { padding-left: 0 }
    > .col-right { padding-right: 0 }

    @media (max-width: $screen-sm-min) {
        .col-full,
        .col-left,
        .col-right, {
            padding-left: 0;
            padding-right: 0;

            .controls {
                padding-left: 30px;
                padding-right: 0;
            }

            .control-label {
                display: none;
            }
        }

        .group-actions {
            .btn {
                display: block;
                width: 100%;
            }
        }
    }
}

.form-login,
.form-forgot-password,
.form-reset {
    @extend %form-default;

    padding: 0 20px;

    .group-action {

        .more {
            //@extend .pull-right;
            padding-left: 10px;

            a {
                text-decoration: underline;
                line-height: 46px;
            }
        }
    }
}


.form-image {
	.image {
		vertical-align: top;

		.del {
			font-size: 16px;
			color: $red;
		}
	}
} // .form-image

.filters {
    @extend .row;

    //visibility: hidden;
    margin-left: 0;
    margin-right: 0;

    margin-top: 5px;
    //border-bottom: 1px solid $hr-border;

    .show-filters {
        text-align: right;
    }

    .form-filters {
        @extend .col-sm-24;
        @extend %form-default;

        background: lighten($color-primary, 5%);
        border: 1 px solid $color-primary;
        padding-top: 18px;
        padding-bottom: 1px;
        margin-bottom: 10px;

        .form-group {
            width: 100%;
            display: block;

            @media (min-width: 768px) {
                .form-control {
                    min-width: 200px;
                }
            }

            &.group-actions {

                .controls {
                    @extend .col-sm-offset-7;
                }
            }
        }

    }

}

.date-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;

  .form-control {
    padding-right: 25px;
  }

  .ico {
    @extend .fa;
    @extend .fa-calendar;

    position: absolute;
    top: 10px;
    right: 12px;
    cursor: pointer;
  }
}

.select2-container--default {
	width: 100% !important;
}

.select2-container--default .select2-selection--single {
    border-color: $input-border;
    border-radius: $input-border-radius;
    height: $input-height-base;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: $input-height-base;
}

.select2-selection__arrow {
    height: $input-height-base;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: $color-primary;
  color: #000
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: lighten($color-primary, 5%); //#e4e4e4;
    border: 1px solid $color-primary; //#aaa;
    border-radius: 3px;
    //color: $color-primary;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    //color: $color-primary;
}

.select2-container--default .select2-selection--multiple {
    border-color: $input-border;
}