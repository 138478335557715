/**
 * MacOS X like theme for elFinder.
 * Required jquery ui "smoothness" theme.
 *
 * @author Dmitry (dio) Levashov
 **/

/* input textarea */
.elfinder input,
.elfinder textarea {
	color: #000;
	background-color: #FFF;
}

/* dialogs */
.std42-dialog, .std42-dialog .ui-widget-content { background-color:#ededed; background-image:none; background-clip: content-box; }

/* navbar */
.elfinder .elfinder-navbar { background:#dde4eb; }
.elfinder-navbar .ui-state-hover  { color:#000; background-color:#edf1f4; border-color:#bdcbd8; }
.elfinder-navbar .ui-state-active { background: #3875d7;    border-color:#3875d7; color:#fff; }
.elfinder-navbar .elfinder-droppable-active {background:#A7C6E5 !important;}
/* disabled elfinder */
.elfinder-disabled .elfinder-navbar .ui-state-active { background: #dadada; border-color:#aaa; color:#fff; }

/* workzone */
.elfinder-workzone { background:#fff; }

/* current directory */
/* selected file in "icons" view */
.elfinder-cwd-view-icons .elfinder-cwd-file .ui-state-hover { background:#ccc; }

/* list view*/
.elfinder-cwd table thead td.ui-state-hover { background:#ddd; }
.elfinder-cwd table tr:nth-child(odd) { background-color:#edf3fe; }
.elfinder-cwd table tr {
	border: 1px solid transparent;
	border-top:1px solid #fff;
}
.elfinder-cwd .elfinder-droppable-active td { background:#A7C6E5 !important; }


/* common selected background/color */
.elfinder-cwd-view-icons .elfinder-cwd-file .elfinder-cwd-filename.ui-state-hover,
.elfinder-cwd table td.ui-state-hover,
.elfinder-button-menu .ui-state-hover { background: #3875d7; color:#fff;}
/* disabled elfinder */
.elfinder-disabled .elfinder-cwd-view-icons .elfinder-cwd-file .elfinder-cwd-filename.ui-state-hover,
.elfinder-disabled .elfinder-cwd table td.ui-state-hover { background:#dadada;}

/* statusbar */
.elfinder .elfinder-statusbar { color:#555; }
.elfinder .elfinder-statusbar a { text-decoration:none; color:#555;}


.std42-dialog .elfinder-help, .std42-dialog .elfinder-help .ui-widget-content { background:#fff;}

/* contextmenu */
.elfinder-contextmenu .ui-state-active { background: #6293df; color:#fff; }
.elfinder-contextmenu .ui-state-hover { background: #3875d7; color:#fff; }
.elfinder-contextmenu .ui-state-hover .elfinder-contextmenu-arrow { background-image:url('../img/arrows-active.png'); }

/* dialog */
.elfinder .ui-dialog input:text.ui-state-hover,
.elfinder .ui-dialog textarea.ui-state-hover {
	background-image: none;
	background-color: inherit;
}
