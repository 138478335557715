
.table-wrap {
    //@extend .table-responsive;

    @media screen and (max-width: $screen-xs-max) {
        overflow-x: auto;
        min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)
        width: 100%;
        margin-bottom: ($line-height-computed * 0.75);
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid $table-border-color;
        padding: 10px;

        // Tighten up spacing
        > .table {
          margin-bottom: 0;

          // Ensure the content doesn't wrap
          > thead,
          > tbody,
          > tfoot {
            > tr {
              > th,
              > td {
                white-space: nowrap;
              }
            }
          }
        }

        // Special overrides for the bordered tables
        > .table-bordered {
          border: 0;

          // Nuke the appropriate borders so that the parent can handle them
          > thead,
          > tbody,
          > tfoot {
            > tr {
              > th:first-child,
              > td:first-child {
                border-left: 0;
              }
              > th:last-child,
              > td:last-child {
                border-right: 0;
              }
            }
          }

          // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
          // chances are there will be only one `tr` in a `thead` and that would
          // remove the border altogether.
          > tbody,
          > tfoot {
            > tr:last-child {
              > th,
              > td {
                border-bottom: 0;
              }
            }
          }

        }
      }

}

.table-default {
    @extend .table-bordered;
    @extend .table-hover;
    @extend .table-striped;

    .col-name, .col-nom, .col-raison-sociale, .col-reference {
        @extend %font-bold;
    }

    > thead > tr > th {
        vertical-align: middle;
    }

    th.col-date {
        width: 7%;
    }

    th.col-status {
        width: 15%;
    }

    th.col-code {
        width: 10%;
    }

    th.col-is_default {
        width: 7%;
    }

    th.col-locality {
        width: 18%;
    }

    th.col-phone {
        width: 13%;
    }

    th.col-volume,
    th.col-weight {
        width: 9%;
    }

    th.col-action-block {
        //width: 6%;
        width: 55px !important;
    }

    th.col-actions {
        //width: 10%;
        width: 100px !important;
    }

    &.table-weighing,
    &.table-removal,
    &.table-request {
        th.col-actions {
            width: 120px !important;
        }
    }

    td.col-date,
    td.col-code,
    td.col-is_default,
    td.col-nomber,
    td.col-weight,
    td.col-volume,
    td.col-action-block,
    td.col-actions,
	td.col-checkbox {
        text-align: center;
    }

    &.table-log {
        td.col-type {
            font-size: 115%;
        }
    }
}