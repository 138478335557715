html {
	height: 100%;
	min-height: 100%;
}

body {
	height: 100%;
	font-family: 'Roboto', verdana, arial, sans-serif;
}


/*
 * Fenêtre de connexion
 */
.container-login {
	margin-top: $navbar-height + 30;
} // .container-login


/*
 * Navigation principale
 */
.navbar-main {
	margin-bottom: 0;
} // .navbar-main


/*
 * Conteneur principal
 */
.main-container {
	padding-top: $navbar-height;

	/*
	 * Navigation verticale
	 */
	.sidebar-nav-main {
		@extend .col-sm-4;
		@extend .hidden-sm;
		@extend .hidden-xs;

		position: fixed;
		top: $navbar-height;
		bottom: 0;
		left: 0;
		z-index: 1000;
		display: block;
		padding: 0;
		overflow-x: hidden;
		overflow-y: auto;
		background-color: $navbar-default-bg;
		border-right: 1px solid $navbar-default-border;
	} // .sidebar-nav-main


	/*
	 * Contenu
	 */
	.content-main {
		@extend .col-xs-24;
		@extend .col-sm-20;
		@extend .col-sm-offset-4;

		padding: 15px 40px;

		.footer {
			padding-top: 20px;
			padding-bottom: 40px;
			margin-top: 40px;
			border-top: 1px solid #eee;

			.auth & {
				text-align: center;
				padding-bottom: 0;

				span {
					display: block;
				}
			}

			p, p a {
				color: #333;
			}

			p {
			   opacity: .6;
			}

			@media (min-width: $screen-sm-min) {
				.alancia {
					float: right;

					.auth & { float: none; }
				}
			}
		}
	} // .content-main

} // .main-container





