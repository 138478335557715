@import "variables";

@import "vendor/select2/select2";
@import "vendor/spectrum/spectrum";
@import "vendor/datatables/datatables";
@import "vendor/elfinder/elfinder.full";
@import "vendor/elfinder/theme";
@import "vendor/jcrop/jquery.Jcrop";
@import "vendor/colorbox/colorbox";

@import "bootstrap";
@import "font-awesome";

@import "utilities/dropdown";
@import "utilities/daterangepicker";
@import "utilities/helpers";
@import "utilities/table";
@import "utilities/form";
@import "utilities/button";

@import "datatables";

//@import "fontello/fontello";
//@import "fontello/animation";
//@import "fontello/fontello-codes";

@import "layout";


/*
 * Conteneur principal
 */
.main-container {
	/*
	 * Navigation verticale
	 */
	.sidebar-nav-main {

		.panel {
			margin: 0;
			box-shadow: none;
			border-radius: 0;

			+ .panel {
				border-top: 0;
			}
		}

		.panel-heading {
			border-radius: 0;
		}

		.panel,
		.panel-heading {
			background: $color-primary;
			border: none;
		}

		.panel-title {

			a {
				display: block;
			}

			.fa {
				margin-right: 10px;
			}
		}

		.panel-body {
			padding: 10px;
			//border-bottom: 1px solid #e7e7e7;
			background: #fff;
		}

		.nav > li > a {
			padding: 7px 15px;
		}

	} // .sidebar-nav-main


	/*
	 * Contenu
	 */
	.content-main {
		.page-header {
			h1 {
				.item-nom {
					font-size: 24px;
				}

				.created-at {
					font-size: 18px;
				}
			} // h1

			.actions-right {
				@extend .pull-right;

				> .dropdown {
					@extend .pull-right;
				}

				> .btn {
					@extend .pull-right;
					margin-right: 2px;
				}
			}
		} // .page-header

		.nav-tabs {
			margin-bottom: 40px;
		} // .nav-tabs

	} // .content-main


	#modal-print {
		.modal-body {
			padding: 0 15px;
		}
	} // #modal-print
} // .main-container