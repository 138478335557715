
.dataTables_wrapper {
	.row {
		//padding-top: 5px;
		//padding-bottom: 10px;
	}

	tr.row-success {
		background-color: $state-success-bg !important;
		color: $state-success-text !important;
	}

	tr.row-danger {
		background-color: $state-danger-bg !important;
		color: $state-danger-text !important;
	}

	td.col-reference,
	td.col-montant-ht, td.col-montant-ttc,
	td.col-montant_ht, td.col-montant_ttc,
	td.col-montant-tva, td.col-taxe,
	td.col-quantite, td.col-gestion-annee,
	td.col-prix-vente-ht, td.col-prix_vente_ht {
		white-space: nowrap;
		text-align: right;
	}

	td.col-reference,
	td.col-gestion-annee {
		text-align: left;
	}

	td.col-checkbox {
		width: 5%;
	}
}

#DataTables_Table_0_processing {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(#fff, .9);

  .inner {
    position: relative;
    width: 100%;
    height: 100%;
    color: #0000ff;
  }

  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .name { padding-left: 10px; }

}

.datatables-filters {
  @extend .col-xs-24;

  select {
    height: 34px;
    margin-left: 10px;
  }

  .filters {
    visibility: visible;
  }
}

.datatables-top-pagination {
  @extend .col-xs-8;
  @extend .col-sm-8;

  select {
    height: 34px;
  }
}

.datatables-bottom-pagination {
  @extend .col-xs-8;
  @extend .col-sm-6;

  text-align: left !important;
  padding-top: 2px;

  select {
    height: 34px;
  }
}

.datatables-bottom-info {
  @extend .col-xs-16;
  @extend .col-sm-6;

  color: $gray-light;

  .dataTables_info {
    padding-top: 0 !important;
    height: 34px;
    line-height: 34px;
  }
}

.datatables-bottom-pages {
  @extend .col-xs-24;
  @extend .col-sm-12;
}

.datatables-search {
  @extend .col-xs-10;
  @extend .col-sm-10;
  //@extend .col-xs-12;
  text-align: right !important;

  input {
    height: 34px;
  }
}

.datatables-buttons {
  @extend .col-xs-6 ;
  @extend .col-sm-6 ;
  text-align: right !important;

  label {
    font-weight: 400;
    padding-right: .5em;

    @media (max-width: $screen-sm-min) {
      display: none;
    }
  }
}

.dataTables_paginate {
  text-align: right;
}

.dataTables_paginate,
.dataTables_length {
  @media (max-width: $screen-sm-min) {
    text-align: left !important;
  }
}

.dataTables_info {
  text-align: center;
  @media (max-width: $screen-sm-min) {
    text-align: right !important;
  }
}