$icon-font-path: '../fonts/';

$grid-columns: 24;

$gray-base:              #000;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #eee

$blue: #274a5e; //#324C5B;
$blue2: #c3cfda; //#324C5B;
$blue-light: lighten($blue, 46.7%);
$blue-lighter: #c3cfda;
/*
$color-primary: #183540;
$color-secondary: #EFEAE2;
$color-ternary: #37A0EB;
*/
$color-primary: #e7e7e7;
$color-secondary: #EFEAE2;
$color-ternary: #666;


//Barre de navigation et sidebar
$navbar-height: 40px;
$navbar-default-color: #fff;
$navbar-default-bg: $color-primary;
$navbar-default-color:  #fff;
$navbar-default-link-color: $color-ternary;
$navbar-default-link-hover-color: $color-ternary;
$navbar-default-link-active-color: $color-ternary;
$navbar-default-border: darken($color-primary, 10%);

$body-padding-top: $navbar-height;

//Panels
/*
$panel-bg:                    #fff;
$panel-body-padding:          15px;
$panel-heading-padding:       10px 15px;
$panel-footer-padding:        5px;
$panel-border-radius:         0;

$panel-inner-border:          #ddd;
$panel-footer-bg:             #f5f5f5;

$panel-default-text:          $gray-dark;
$panel-default-border:        #ddd ;
$panel-default-heading-bg:    #f5f5f5;
*/
/*
$table-bg-accent: lighten($blue-lighter, 15%);
$table-bg-hover: lighten($blue-lighter, 12%);
$table-bg-active: $table-bg-hover;
$table-border-color: lighten($blue-lighter, 5%);

$page-header-border-color: lighten($blue-lighter, 10%);

$hr-border: lighten($blue-lighter, 10%);
*/

/*
$legend-color: $blue;
$legend-border-color: lighten($blue-lighter, 10%);
*/
/*
$nav-link-hover-bg: lighten($blue-lighter, 10%);
$component-active-bg: lighten($blue-lighter, 10%);
$component-active-color: $blue;
*/
/*
$brand-primary: #274a5e;
$brand-info: lighten($brand-primary, 46.7%);
$brand-success: #82AE28;
$brand-warning: #FFCC00;
$brand-danger: #E84E1B;
*/

$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #FFCC00 !default;
$brand-danger:          #d9534f !default;

$red: $brand-danger;