


.col-actions .dropdown, .action .dropdown {
	.btn-dropdown-actions {
		@extend .btn-default;
	} // .btn-dropdown-actions

	.dropdown-menu {
		left: auto;
		right: 0;
		padding: 0;

		button {
			border: none;
			background: none;
			color: $dropdown-link-hover-color;
		}

		li a, li button {
			padding: 5px 20px;
		}

		.ico {
			@extend .fa;
			@extend .fa-fw;
		}

		.edit .ico {
			@extend .fa-pencil-square;
		}

		.show .ico {
			@extend .fa-eye;
		}

		.duplicate .ico {
			@extend .fa-files-o;
		}

		.index .ico {
			@extend .fa-plus;
		}

		.accept .ico {
			@extend .fa-check;
		}

		.deny .ico {
			@extend .fa-times;
		}

		.cancel .ico {
			@extend .fa-ban;
		}

		.mail .ico {
			@extend .fa-envelope
		}

		.show-pdf .ico {
			@extend .fa-print
		}

		.download .ico {
			@extend .fa-download
		}

		.delete .ico {
			@extend .fa-trash
		}

		.delete {
			.dropdown-delete {
				@extend .btn-danger;
			}
		} // .delete
	} // .dropdown-menu
} // .dropdown