%button {
    @extend %font-bold;

    .ico {
        margin-top: 1px;
        margin-right: 5px;
        font-size: 115%;
    }

    td & {
        @extend .btn-sm;

        .ico {
            //@extend .fa-1x;
            margin-right: 0;
        }

        .name {
            display: none;
        }

        + .btn-group {
            margin-top: 4px;
        }
    }
}

.btn-back,
.btn-show,
.btn-filter,
.btn-cancel-weighing,
.btn-accept-weighing,
.btn-validate-weighing,
.btn-validate-removal,
.btn-actions,
.btn-login,
.btn-forgot-password,
.btn-reset,
.btn-add,
.btn-duplicate,
.btn-save,
.btn-refresh,
.btn-edit,
.btn-finish,
.btn-doblock,
.btn-dounblock,
.btn-download,
.btn-index,
.btn-delete {
    @extend %button;
}

.btn-save,
.btn-refresh,
.btn-filter,
.btn-actions,
.btn-edit,
.btn-finish,
.btn-login,
.btn-forgot-password,
.btn-download,
.btn-reset {
    @extend .btn-primary;
}


.btn-show,
.btn-duplicate,
.btn-index,
.btn-add {
    @extend .btn-success;
}

.btn-doblock,
.btn-dounblock {
    @extend .btn-warning;

    color: #a88700;
}

.btn-dounblock {
    opacity: .5;
}

.btn-back,
.btn-see,
.btn-cancel {
    @extend .btn-default;
}

.btn-cancel-weighing,
.btn-delete {
    @extend .btn-danger;
}

.btn-login,
.btn-forgot-password,
.btn-reset {
    @extend .btn-lg;
}

.btn-cancel {
    text-decoration: none !important;
}

.btn-save .ico {
    @extend .fa;
    @extend .fa-check-square;
}

.btn-add .ico {
    @extend .fa;
    @extend .fa-plus-square;
}

.btn-duplicate .ico {
    @extend .fa;
    @extend .fa-files-o;
}

.btn-edit .ico {
    @extend .fa;
    @extend .fa-pencil-square;
}

.btn-delete .ico {
    @extend .fa;
    @extend .fa-trash;
}

.btn-doblock .ico {
    @extend .fa;
    @extend .fa-eye-slash;
}

.btn-dounblock .ico {
    @extend .fa;
    @extend .fa-eye;
}

.btn-filter .ico {
    @extend .fa;
    @extend .fa-filter;
}

.btn-show .ico {
    @extend .fa;
    @extend .fa-eye;
}

.btn-back .ico {
    @extend .fa;
    @extend .fa-arrow-left;
}

.btn-download .ico {
    @extend .fa;
    @extend .fa-download;
}

.btn-index .ico {
	@extend .fa;
	@extend .fa-plus;
}